import { DateTime } from 'luxon';
import { calculateAspectRatio } from "../../utils";
import './stylesheet.css'

import clear from "../../assets/icons/clear.png";
import cloudy from "../../assets/icons/cloudy.png";
import degree_current from "../../assets/icons/degree-current.png";
import degree_hour from "../../assets/icons/degree-hour.png";
import degree_symbol from "../../assets/icons/degree-symbol.png";
import fog from "../../assets/icons/fog.png";
import lightning from "../../assets/icons/lightning.png";
import partly_cloudy_day from "../../assets/icons/partly-cloudy-day.png";
import partly_cloudy_night from "../../assets/icons/partly-cloudy-night.png";
import rain from "../../assets/icons/rain.png";
import rectangle from "../../assets/icons/rectangle.png";
import showers from "../../assets/icons/showers.png";
import sleet from "../../assets/icons/sleet.png";
import small from "../../assets/icons/small.png";
import snow_flurries from "../../assets/icons/snow-flurries.png";
import snow from "../../assets/icons/snow.png";
import sunny from "../../assets/icons/sunny.png";
import thunderstorm from "../../assets/icons/thunderstorm.png";
import weather_and_line from "../../assets/icons/weather-and-line.png";
import wind from "../../assets/icons/wind.png";
import { useStateValue } from "../../state/StateProvider";
import { getTimeZone } from "../../utils/getTimezone";

const iconMap = {
  clear,
  cloudy,
  degree_current,
  degree_hour,
  degree_symbol,
  fog,
  lightning,
  partly_cloudy_day,
  partly_cloudy_night,
  rain,
  rectangle,
  showers,
  sleet,
  small,
  snow_flurries,
  snow,
  sunny,
  thunderstorm,
  weather_and_line,
  wind
}

function getIcon(str, timeOfDay='day') {
  let key = str;
  if (key === 'partly cloudy') key = `partly_cloudy_${timeOfDay}`
  key = key.replace(/ /g, '_');
  key = key.replace(/-/g, '_');
  return iconMap[key]
}

function WeatherWidget({frame_width, frame_height, weatherData, creative}) {
  const [{contentVars}] = useStateValue();
  if (weatherData && creative) {
    const {ixn_connect_site_id, ixn_connect_network_key} = contentVars;
    const timezone = getTimeZone(ixn_connect_site_id, ixn_connect_network_key);
    const aspectRatio = calculateAspectRatio(creative?.width, creative.height);
    const adFormat = aspectRatio ? aspectRatio : `${creative?.width}x${creative.height}`
  
    return (
        <div className={`weather-container _${frame_width}x${frame_height} _${adFormat}`}>
          <div className="content">
            <div className="current">
              <img src={getIcon(weatherData.hourly[0].icon)} />
              <div className="temp">
                {weatherData.hourly[0].temperature.value}
                <img src={degree_current} />
              </div>
            </div>
            <div className="forecast">
            <div>
              <div className="time">{DateTime.fromISO(weatherData.hourly[2].date, { zone: timezone }).toFormat('ha').toLowerCase()}</div>
              <img src={getIcon(weatherData.hourly[2].icon)} />
              <div className="temp">
                <span>{weatherData.hourly[2].temperature.value}</span><img src={degree_symbol} style={{display: "inline"}} />
              </div>
            </div>
            <div>
              <div className="time">{DateTime.fromISO(weatherData.hourly[4].date, { zone: timezone }).toFormat('ha').toLowerCase()}</div>
              <img src={getIcon(weatherData.hourly[4].icon)} />
              <div className="temp">
                <span>{weatherData.hourly[4].temperature.value}</span><img src={degree_symbol} style={{display: "inline"}} />
              </div>
            </div>
            <div>
              <div className="time">{DateTime.fromISO(weatherData.hourly[6].date, { zone: timezone }).toFormat('ha').toLowerCase()}</div>
              <img src={getIcon(weatherData.hourly[6].icon)} />
              <div className="temp">
                <span>{weatherData.hourly[6].temperature.value}</span><img src={degree_symbol} style={{display: "inline"}} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <></>
}

export default WeatherWidget;
