import React from 'react';
import {IABImage, IABVideo, IABadm} from './';

const CreativeContainer = ({frame_width, frame_height, creative}) => {
  if (creative.type == 'adm') {
    return <IABadm frame_width={frame_width} frame_height={frame_height} creative={creative} />
  } else if (creative.type == 'display') {
    return <IABImage frame_width={frame_width} frame_height={frame_height} creative={creative} />
  }  else if (creative.type == 'video') {
    return <IABVideo frame_width={frame_width} frame_height={frame_height} creative={creative} />
  }
}

export default CreativeContainer;