import React, { useEffect } from 'react';
import {formatStyles, calculateAspectRatio, pamphletPlayVideo} from '../utils'

const IABVideo = ({frame_width, frame_height, creative}) => {
  useEffect(() => pamphletPlayVideo(creative), [])

  let imageStyles;
  let containerStyles = {};
  let screenFormat = `${frame_width}x${frame_height}`;

  const aspectRatio = calculateAspectRatio(creative.width, creative.height);
  const adFormat = aspectRatio ? aspectRatio : `${creative.width}x${creative.height}`

  const screenFormatConfig = formatStyles[screenFormat]
  if (screenFormatConfig) {
    imageStyles = formatStyles[screenFormat]['iabStyles'][adFormat]
    containerStyles = formatStyles[screenFormat]['containerStyles'][adFormat]
  } 
  if (!imageStyles) {
    imageStyles = {
      width: `${frame_width}px`,
      height: `${frame_height}px`,
      position: 'absolute',
      top: '0px',
      left: '0px',
      objectFit: 'contain',
      backgroundColor: 'black'
    }
  }

  containerStyles = Object.assign({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    position: 'absolute'
  }, containerStyles)

  return (
    <div style={containerStyles}>
      <div>
        <div id="videoContainer" style={{...imageStyles, display: 'inline-block', backgroundColor: 'black'}} />
      </div>
    </div>
  );
}

export default IABVideo;