 import { CreativeContainer } from "../../components";
import Placeholder from "../../components/Placeholder";
import { useStateValue } from "../../state/StateProvider";

function FullScreenPage(props) {
  const [{auctionHouseBid, contentVars}] = useStateValue();
  if (auctionHouseBid && contentVars) {
    const frame_res = contentVars.frame_resolution || '1080x1920'
    const width = frame_res.split('x')[0]
    const height = frame_res.split('x')[1]
    return (
      <CreativeContainer frame_width={width} frame_height={height} creative={auctionHouseBid.creative} />
    )
  }
  return <>
    <Placeholder />
  </>
}

export default FullScreenPage;
