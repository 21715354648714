export const getTimeZone = (siteId, networkKey) => {
	const state_timezone_map = {	
		"AL": "CST",
		"AK": "AKST",
		"AZ": "MDT",
		"AR": "CST",
		"CA": "PST",
		"CO": "MST",
		"CT": "EST",
		"DE": "EST",
		"DC": "EST",
		"FL": "EST",
		"GA": "EST",
		"HI": "HST",
		"ID": "MST",
		"IL": "CST",
		"IN": "EST",
		"IA": "CST",
		"KS": "CST",
		"KY": "CST",
		"LA": "CST",
		"ME": "EST",
		"MD": "EST",
		"MA": "EST",
		"MI": "EST",
		"MN": "CST",
		"MS": "CST",
		"MO": "CST",
		"MT": "MST",
		"NE": "CST",
		"NV": "PST",
		"NH": "EST",
		"NJ": "EST",
		"NM": "MST",
		"NY": "EST",
		"NC": "EST",
		"ND": "CST",
		"OH": "EST",
		"OK": "CST",
		"OR": "PST",
		"PA": "EST",
		"RI": "EST",
		"SC": "EST",
		"SD": "CST",
		"TN": "CST",
		"TX": "CST",
		"UT": "MST",
		"VT": "EST",
		"VA": "EST",
		"WA": "PST",
		"WV": "EST",
		"WI": "CST",
		"WY": "MST",
	}

	const timezone_name_map = {
		"EST": 'America/New_York',
		"CST": 'America/Chicago',
		"PST": 'America/Los_Angeles',
		"MST": 'America/Denver',
		"HST": 'Pacific/Honolulu',
		"AKST": 'America/Anchorage'
	}

	const isLink = networkKey.toLowerCase().indexOf('link') > -1;
	let abbr = siteId.split('-')
	if (isLink || abbr.length < 1) return timezone_name_map["EST"] 
	abbr = abbr[1]

        const state_timezone = state_timezone_map[abbr.toUpperCase()]
	if (state_timezone) {
		return timezone_name_map[state_timezone]
	}
	return timezone_name_map["EST"]
}