import BSP from "bspeasy"

export default (creative) => {
  const videoProps = document.querySelector('#videoContainer').getBoundingClientRect();
  const isProduction = process.env.NODE_ENV != 'production'
  let url = creative.url || ''
  const isRemoteUrl = url.startsWith('http')
  // if (isProduction && isRemoteUrl) {
  //   url = `/opt/broadsign/suite/bsp/share/bsp/sync/auction-house-videos/${creative.name}.mp4`
  // }
  BSP.playVideo(url, videoProps.left, videoProps.top, videoProps.width, videoProps.height);
  window.videoPrepared = true;
}