let _1920x1080 = { 
	iabStyles: {
		'160x600': {
			width: '240px',
			height: '900px'
		},
		'300x250': {
			width: '780px',
			height: '650px',
		},
		'728x90': {
			width: '1230px',
			height: '152px'
		},
		'9:16': {
			width: '450px',
			height: '800px'
		}
	},
	containerStyles: {
		'160x600':  {
			width: '20%',
			height: '100%',
			right: '0px'
		},
		'300x250':  {
			width: '50%',
			height: '100%',
			right: '0px'
		},
		'728x90':  {
			width: '100%',
			height: '35%',
			bottom: '0px'
		},
		'9:16':  {
			width: '50%',
			height: '100%',
			right: '0px'
		}
	}
}


let _1920x960 = {
	iabStyles: {
		..._1920x1080.iabStyles,
		'160x600': {
			width: '720px',
			height: '192px'
		},
	},
	containerStyles: {..._1920x1080.containerStyles}
}

let _1080x1920  = {
	iabStyles: {
		'160x600': {
			width: '432px',
			height: '1620px',
			iframe: {
				transform: 'scale(2.7)',
				transformOrigin: 'top left',
				border: 'none'
			}
		},
		'300x250': {
			width: '780px',
			height: '650px',
			iframe: {
				transform: 'scale(2.6)',
				transformOrigin: 'top left',
				border: 'none'
			}
		},
		'728x90': {
			width: '946px',
			height: '117px',
			iframe: {
				transform: 'scale(1.3186)',
				transformOrigin: 'top left',
				border: 'none'
			}
		},
		'16:9': {
			width: '780px',
			height: '439px'
		},
		'4:3': {
			width: '585px',
			height: '780px'
		}
	},
	containerStyles: {
		'160x600': {
			width: '50%',
			height: '100%',
			right:  '0px'
		},
		'300x250': {
			width: '100%',
			height: '50%',
			bottom:  '0px'
		},
		'728x90': {
			width: '100%',
			height: '15%',
			bottom:  '65px'
		},
		'16:9': {
			width: '100%',
			height: '50%',
			bottom:  '0px'
		},
		'4:3': {
			width: '100%',
			height: '50%',
			bottom:  '0px'
		}
	}
}

let _1080x1440  = {
	iabStyles: {
		..._1080x1920.iabStyles,
		'160x600': {
			width: '304px',
			height: '1140px'
		},
		'containerStyles': {
			..._1080x1920.containerStyles,
			'300x250': {
				width: '100%',
				height: '70%',
				bottom:  '0px'
			},
			'728x90': {
				width: '100%',
				height: '20%',
				bottom:  '0px'
			},
			'4:3': {
				width: '100%',
				height: '60%',
				bottom:  '0px'
			}
		}
	}
}

export let formatStyles = {
	'1920x1080': _1920x1080,
	'1920x960': _1920x960,
	'1080x1920': _1080x1920,
	'_1080x1440': _1080x1440
}