import React from 'react';
import {formatStyles, calculateAspectRatio} from '../utils';

const IABadm = ({frame_width, frame_height, creative}) => {
  let iframeStyles;
  let containerStyles = {};
  const screenFormat = `${frame_width}x${frame_height}`;

  const aspectRatio = calculateAspectRatio(creative.width, creative.height);
  const adFormat = aspectRatio ? aspectRatio : `${creative.width}x${creative.height}`

  const screenFormatConfig = formatStyles[screenFormat]

  if (screenFormatConfig) {
    iframeStyles = formatStyles[screenFormat]['iabStyles'][adFormat]
    if (iframeStyles) {
        iframeStyles = Object.assign(iframeStyles, iframeStyles['iframe'])
    }
    containerStyles = formatStyles[screenFormat]['containerStyles'][adFormat]
  } 
  if (!iframeStyles) {
    iframeStyles = {
      width: `${frame_width}px`,
      height: `${frame_height}px`,
      position: 'absolute',
      top: '0px',
      left: '0px',
      objectFit: 'contain',
      backgroundColor: 'black'
    }
  }

  containerStyles = Object.assign({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    position: 'absolute'
  }, containerStyles)

  return (
    <div style={containerStyles}>
      <div>
        <div style={{textAlign: 'center', paddingBottom: '25px', fontSize: '25px', color: 'rgba(255,255,255,.5)'}}>Adversitement</div>
        <iframe style={iframeStyles} srcDoc={creative.adm} sandbox="allow-scripts allow-same-origin" onLoad={(e) => {
            const iframe = e.target;
            const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
            const iframeBody = iframeDoc.querySelector('body');
            if (iframeBody) {
              iframeBody.style.margin = '0';
            }
          }}/>
      </div>
    </div>
  );
}

export default IABadm;