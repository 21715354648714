import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import WeatherPage from './pages/weather/WeatherPage';
import FullScreenPage from './pages/fullscreen/FullScreenPage';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route exact path="/" element={<Navigate to="/weather" />} />
          <Route path="/weather" element={<WeatherPage />} />
          <Route path="/fullscreen" element={<FullScreenPage />} />
      </Routes>
    </BrowserRouter >
  );
};

export { Router };
