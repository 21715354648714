import { useEffect } from 'react';
import './App.css';
import { Router } from './Router';
import { useStateValue } from './state/StateProvider';
import prebidHanlder from './utils/PrebidHandler';

function App() {
  const dispatch = useStateValue()[1];
  useEffect(() => {
    async function initializeAndSetReady() {
      const {contentVars, ad} = await prebidHanlder.initialize();
      dispatch({
        type: "setAuctionHouseBid",
        value: ad
      });
      dispatch({
        type: "setContentVars",
        value: contentVars
      })
    }
    initializeAndSetReady();
  }, [dispatch]);

  return (
     <Router />
  );
}

export default App;
