import { useEffect, useState } from "react";
import { useStateValue } from "../../state/StateProvider";
import { fetchData } from "../../utils/fetchData";
import { styled } from "styled-components";
import day_bg from '../../assets/1080x1920-day.png';
import night_bg from '../../assets/1080x1920-night.png';
import { DateTime } from 'luxon';
import WeatherWidget from "./WeatherWidget";
import { CreativeContainer } from "../../components";

const Container = styled.div`
  width: 1080px;
  height: 1920px;
  position: relative;
  overflow: hidden;
  background-image: ${props => (props.$isNight ? `url(${night_bg})` : `url(${day_bg})`)};
`;

function WeatherPage(props) {
  const [{auctionHouseBid, contentVars}] = useStateValue();
  const [weatherData, setWeatherData] = useState();
  const [isNight, setIsNight] = useState(false)

  useEffect(() => {
    const fetchWeather = async () => {
      const {display_unit_lat_long} = contentVars;
      const lat = display_unit_lat_long.split(',')[0]
      const lon = display_unit_lat_long.split(',')[1]
      const root_url = 'https://siphon.platform.linksvc.com';
      const api_key = 'f544c7ab099f0004ade7bbcfe44afcb3';
      const res = await fetchData(`${root_url}/weather/forecast?apikey=${api_key}&geolocation=${lat},${lon}`, 'get');
      
      if (!res.error) {
        setWeatherData(res);
        const currentTime = DateTime.now();
        const sunsetTime = DateTime.fromISO(res?.daily[0]?.sunset);
        const sunriseTime = DateTime.fromISO(res?.daily[0]?.sunrise);
        setIsNight(currentTime >= sunsetTime || currentTime <= sunriseTime)

      }
    }
    if (contentVars) fetchWeather();
  }, [contentVars])
  
  const test_styles = {
    position: 'absolute',
    top: 100,
    left: 100,
    width: 300,
    height: 250
  }

  if (auctionHouseBid && weatherData) {
    const frame_res = contentVars.frame_resolution || '1080x1920'
    const width = frame_res.split('x')[0]
    const height = frame_res.split('x')[1]
    return (
      <Container $isNight={isNight}>
        <WeatherWidget frame_width={width} frame_height={height} weatherData={weatherData} creative={auctionHouseBid.creative} />
        <CreativeContainer frame_width={width} frame_height={height} creative={auctionHouseBid.creative} />
      </Container>
    )
  }
  return <></>
}

export default WeatherPage;
