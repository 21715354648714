import axios from 'axios';
import { getContentVars, shuffleArray } from '.';
import { type } from '@testing-library/user-event/dist/type';

class PrebidHanlder {
  constructor(prebidHost) {
    this.prebidHost = prebidHost;
    this.contentVars = getContentVars();
  }

  async fetchBidFromPrebidServer(payload) {
    try {
      const response = await axios.post(this.prebidHost, payload);
      return response.data;
    } catch (error) {
      console.error('Error fetching bid from Prebid server:', error);
      return {};
    }
  }

  formatBidResponse(bid) {
    bid = bid.seatbid?.[0]?.bid?.[0]
    if (bid) {
      let type = 'display';
      const creativeUrl = bid.iurl;
      if (creativeUrl && creativeUrl.endsWith('.mp4')) {
        type = 'video';
      }
      let response = {
        id: bid.id,
        creative: {
          width: bid.w,
          type,
          height: bid.h,
          burl: bid.burl,
        },
        bid_info: {
          bid_id: bid.id,
          imp_x: null,
          bid_price: bid.price,
        },
      };
      if (!bid.iurl && bid.adm) {
        response.creative.adm = bid.adm;
        response.creative.type = 'adm';
      } else {
        response.creative.url = creativeUrl;
      }
      return response;
    }
  }

  getPayload(contentVars) {
    let width, height;
    if (contentVars.frame_resolution) {
      width = Number(contentVars.frame_resolution.split('x')[0]);
      height = Number(contentVars.frame_resolution.split('x')[1]);
    } else {
      width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    }

    let supportedIabFormats = [[300, 250], [160, 600], [728, 90], [width, height]];
    supportedIabFormats = [[1920,1080]];
    shuffleArray(supportedIabFormats)

    const payload = {
      id: '1',
      dooh: {
        id: "publisher-intersection"
      },
      imp: [
        {
          id: '1',
          banner: { format: supportedIabFormats.map(([w, h]) => ({ w, h })) },
          ext: {
            hivestack: {
              unitUUID: (contentVars.hivestack_unit_uuid || "35314082-eb97-45d5-917c-65c7f0e06c2c"),
            }
          },
        },
      ],
    };

    return payload;
  }

  async initializeStandalone() {
    const payload = this.getPayload(this.contentVars);
    const bidResponse = await this.fetchBidFromPrebidServer(payload);
    const ad = this.formatBidResponse(bidResponse);
    return { contentVars: this.contentVars, ad };
  }

  initializeInFrame() {
    return new Promise((resolve) => {
      let contentVars, ad;

      const handleMessage = (event) => {
        if (typeof event.data === 'string') {
          try {
            const data = JSON.parse(event.data);
            if (data.type === 'auctionHouseAd') {
              ad = data;
            } else if (data.type === 'contentVars') {
              contentVars = data;
            }
            if (contentVars && ad) resolve({ contentVars, ad });
          } catch (error) {
            console.error('Error parsing postmessage JSON:', error);
          }
        }
      };

      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    });
  }

  async initialize() {
    if (this.contentVars['standalone'] === 'true') {
      return this.initializeStandalone();
    } else {
      return this.initializeInFrame();
    }
  }
}

const prebid_host = 'https://pbs.dev.linksvc.com/openrtb2/auction';
const prebidHanlder = new PrebidHanlder(prebid_host);

export default prebidHanlder;
