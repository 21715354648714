const _1920x1080 = {
	'160x600': {
		fontSize: '200px',
		width: '90%',
		height: '100%'
	},
	'300x250': {
		fontSize: '200px',
		width: '50%',
		height: '100%'
	},
	'728x90':  {
		fontSize: '200px',
		width: '100%',
		height: '66%'
	},
	'9:16': {
		fontSize: '200px',
		width: '50%',
		height: '100%'
	}
} 

const _1920x960 = {..._1920x1080}

const _1080x1920  = {
	'160x600': {
		fontSize: '180px',
		width: '50%',
		height: '100%'
	},
	'300x250': {
		fontSize: '244px',
		width: '100%',
		height: '66%'
	},
	'728x90': {
		fontSize: '244px',
		width: '100%',
		height: '66%'
	},
	'16:9': {
		fontSize: '244px',
		width: '100%',
		height: '50%'
	},
	'4:3': {
		fontSize: '244px',
		width: '100%',
		height: '66%'
	}
}

const _1080x1440  = {
	..._1080x1920,
	'160x600': {
		fontSize: '180px',
		width: '60%',
		height: '100%'
	},
	'300x250': {
		fontSize: '244px',
		width: '100%',
		height: '40%'
	},
	'4:3': {
		fontSize: '244px',
		width: '100%',
		height: '40%'
	}
}

export const timeFormatStyles = {
	'1920x1080': _1920x1080,
	'1920x960': _1920x960,
	'1080x1920': _1080x1920,
	'_1080x1440': _1080x1440
}