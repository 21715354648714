export const reducer = (state, action) => {
  switch (action.type) {
    case 'setAuctionHouseBid':
      return {
        ...state,
        auctionHouseBid: action.value
      };
    case 'setContentVars':
      return {
        ...state,
        contentVars: action.value
      }
      
    default:
    return state;
  }
}

