import {formatStyles} from './iabFormatStyles';
import {timeFormatStyles}  from  './timeStyles';
// import addVideoEndStopCommand from './videoStopMethod';
import pamphletPlayVideo from './pamphletPlayVideo';
export {formatStyles, pamphletPlayVideo, timeFormatStyles};


export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
}

export function calculateAspectRatio(width, height) {
	const ratio_map = {
		"1.778": "16:9",
		"1.333": "4:3",
		"0.563": "9:16",
	}
	const percentile = String((width / height).toFixed(3))
	if (ratio_map[percentile]) return ratio_map[percentile]
}

export function getContentVars() {
  // return {
  //   display_unit_lat_long: '-73,40',
  //   standalone: 'true',
  //   ixn_connect_network_key: 'linknyc',
  //   frame_resolution: '1080x1920',
  //   ixn_connect_site_id: 'mn-00-00000-l'
  // }
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const queryParams = {};

  for (const [key, value] of urlParams.entries()) {
    queryParams[key] = value;
  }
  return { ...(window.BroadSignObject || {}), ...queryParams };
}