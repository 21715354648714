import React from 'react';
import placeholder from '../assets/fallback/prebid-fallback.jpg';

const Placeholder = () => {
    const styles = {
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            overflow: 'hidden',
            background: '#8D8B85',
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        },
        image: {
            width: '100%',
            margin: '0',
            objectFit: 'contain'
        }
    }

    return (
        <div style={styles.container}>
            <img style={styles.image} src={placeholder} />
        </div>
    )
}

export default Placeholder;